import { CHANGE_APPROVED_ADDRESS, CHANGE_APPROVED_LIMIT, CHANGE_APPROVED_REQUEST_FEE, CHANGE_APPROVED_WITHDRAW_FEE, CHANGE_CLAIM_FEE, CHANGE_COMMIT_FEE, CHANGE_EXTRA_PERIOD, CHANGE_FEE_VAULT_BURNED_RATE, CHANGE_GAS_FEE, CHANGE_GOVERNOR_ADDRESS, CHANGE_MIN_VRZR, CHANGE_PERMANENT_GOVERNOR, CHANGE_UNAPPROVED_REQUEST_FEE, CHANGE_UNAPPROVED_WITHDRAW_FEE, CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VRZR, CHANGE_EXPENSE_ACCOUNT } from "../../utils/constants";

export const actionsData = [
    { actionFlag: CHANGE_APPROVED_ADDRESS, action: "Change approved borrower", firstParam: "Position", secondParam: "Address", firstUnit: "", secondUnit: "", note: "" },
    { actionFlag: CHANGE_APPROVED_LIMIT, action: "Change limit pool count", firstParam: "New value", secondParam: "", firstUnit: "", secondUnit: "", note: "" },
    { actionFlag: CHANGE_APPROVED_REQUEST_FEE, action: "Change request fee for approved borrowers", firstParam: "New value", secondParam: "", firstUnit: "RZR", secondUnit: "", note: "" },
    { actionFlag: CHANGE_APPROVED_WITHDRAW_FEE, action: "Change withdrawal fee for approved borrowers", firstParam: "New value", secondParam: "", firstUnit: "", secondUnit: "", note: "(100 ~ 1%, min: 0%, max: 100%)",valid: {min: 0, max: 10000} },
    { actionFlag: CHANGE_UNAPPROVED_REQUEST_FEE, action: "Change request fee for unapproved borrowers", firstParam: "New value", secondParam: "", firstUnit: "RZR", secondUnit: "" },
    { actionFlag: CHANGE_UNAPPROVED_WITHDRAW_FEE, action: "Change withdrawal fee for unapproved borrowers", firstParam: "New value", secondParam: "", firstUnit: "", secondUnit: "", note: "(100 ~ 1%, min: 0%, max: 100%)",valid: {min: 0, max: 10000} },
    { actionFlag: CHANGE_COMMIT_FEE, action: "Change commit fee", firstParam: "New value", secondParam: "", firstUnit: "", secondUnit: "", note: "(100 ~ 1%, min: 0%, max: 100%)",valid: {min: 0, max: 10000} },
    { actionFlag: CHANGE_CLAIM_FEE, action: "Change claim fee", firstParam: "New value", secondParam: "", firstUnit: "", secondUnit: "", note: "(100 ~ 1%, min: 0%, max: 100%)",valid: {min: 0, max: 10000} },
    { actionFlag: CHANGE_GAS_FEE, action: "Change gas fee", firstParam: "New value", secondParam: "", firstUnit: "RZR", secondUnit: "", note: "" },
    { actionFlag: CHANGE_EXTRA_PERIOD, action: "Change extra period", firstParam: "New value", secondParam: "", firstUnit: "Mins", secondUnit: "", note: "" },
    { actionFlag: CHANGE_GOVERNOR_ADDRESS, action: "Change metalend governor address", firstParam: "New address", secondParam: "", firstUnit: "", secondUnit: "", note: "" },
    { actionFlag: CHANGE_FEE_VAULT_BURNED_RATE, action: "Change fee vault burned rate", firstParam: "New value", secondParam: "", firstUnit: "", secondUnit: "", note: "(100 ~ 1%, min: 50%, max: 100%)",valid: {min: 5000, max: 10000} },
    { actionFlag: CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VRZR, action: "Change USDC distribution rate", firstParam: "New value", secondParam: "", firstUnit: "", secondUnit: "", note: "(100 ~ 1%, min: 50%, max: 100%)",valid: {min: 5000, max: 10000} },
    { actionFlag: CHANGE_EXPENSE_ACCOUNT, action: "Change expense account", firstParam: "New address", secondParam: "", firstUnit: "", secondUnit: "", note: "" },
    { actionFlag: CHANGE_MIN_VRZR, action: "Change min veRZR", firstParam: "New value", secondParam: "", firstUnit: "vRZR", secondUnit: "", note: "" },
    { actionFlag: CHANGE_PERMANENT_GOVERNOR, action: "Change permanent governor", firstParam: "Position", secondParam: "Address", firstUnit: "", secondUnit: "", note: "" },
];
import { PublicKey } from "@solana/web3.js";
import { NETWORK_RPC } from "./constants";

import idl from "./idl/metalend.json";
import dao_idl from "./idl/dao.json";
import aution_idl from "./idl/auction.json";

export const WSOL_MINT_KEY = new PublicKey(
  "So11111111111111111111111111111111111111112"
);

export const LEND_IDL = idl;
export const DAO_IDL = dao_idl;
export const AUCTION_IDL = aution_idl;

export const TOKEN_INFO = {
  mainnet: [
    {
      token_name: "SPL-USDC",
      token_symbol: "USDC",
      token_mint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    },
  ],
  devnet: [
    {
      token_name: "SPL-USDC",
      token_symbol: "USDC",
      token_mint: "Raisg93hadrPtL4YG49JyZeT4KACJg1T7gB7sPpdNUe",
    },
  ],
  localnet: [
    {
      token_name: "SPL-USDC",
      token_symbol: "USDC",
      token_mint: "Raisg93hadrPtL4YG49JyZeT4KACJg1T7gB7sPpdNUe",
    },
  ],
};

export const DAO_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "mainnet"
    ? "CAtA8VjQWuCDKZ6k319RA3BAHQMvQpVfsi2sAnewX9q9"
    : "7oQMtAiht1jLmyVcs8yDLzWifpn7rMHMFdxWojtmnYJp"
);

export const AUCTION_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "mainnet"
    ? "Amq7gyCGcHtxY8mpwbF6D2w9ApQMYdUcbvD9rhhHsE6M"
    : "6JKiz5ZExVXFDUViwcEEtGZn3fDv66YnEVD2J25HM2Bx"
);

export const LEND_PROGRAM_ID = new PublicKey(
  NETWORK_RPC === "mainnet"
    ? "6xf7FBquVuWMGqXUQjK7wPcsJg8AZJ3ovHQ1QqR316Kx"
    : "5y6iVoBWAvQj8xQkq6EH1yeRnnuY4GGHF87aWeqkSm8X"
);

console.log(NETWORK_RPC);
export const SB_MINT_ADDRESS =
  NETWORK_RPC === "mainnet"
    ? "RZRyr758BfvKcQTVp1XQoCe9BH2t724XQkLJkiikQ5f"
    : "RaiRQjbzkEskpx69iX3RDv41zSppJfEMNU8K6fDnc66";
export const USDC_MINT_ADDRESS =
  NETWORK_RPC === "mainnet"
    ? "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    : "Raisg93hadrPtL4YG49JyZeT4KACJg1T7gB7sPpdNUe";

export let TOKEN_LIST: any =
  NETWORK_RPC === "mainnet" ? TOKEN_INFO.mainnet : TOKEN_INFO.devnet;

if (NETWORK_RPC === "mainnet") TOKEN_LIST = TOKEN_INFO.mainnet;

export const EMPTY_ADDRESS = "11111111111111111111111111111111";

export const GOVERNOR_ADDRESS = 'NamwG25LxCe6PvAEoTUGebYPWFaj7HLdyS2texLPH5Q';
export const APPROVER0_ADDRESS = 'DM147esm3PuxwUTd5ZatWXkPEhdqzdXK3MJppe6cWpW7';

export const VLNDR = 'F4EoyAxVDGoCSsQssaJfwJryJCioxxJJ54abPxQDQxBW'; 
export const LNDR_VAULT_PUBLICKEY = '8AvFn9uCCZvaa5wdbFb6Sj5DhyCzKyANAUhFyHKasgur'; 
export const VLNDR_VAULT_PUBLICKEY = '39y1iMRxcNvtCxq1Rs9zWdpGUephxykGQM9S6qq2XfZu'; 
export const USDC_VAULT_PUBLICKEY = '8Y3SG2TXT9qnjNDH1P6nmSRwA2VFB8u8z5ZVg5tjSccs';

export const DEX_PRUNE_AUTHORITY = new PublicKey(
  NETWORK_RPC === "mainnet"
    ? "7PdpUeUJndmJ2AdPZLK5exR17Qz2q82KkSckRFjsmg6R"
    : "7PdpUeUJndmJ2AdPZLK5exR17Qz2q82KkSckRFjsmg6R"
);
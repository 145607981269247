export const GLOBAL_STATE = 'global-state';

export const GOVERNOR_LIST = 'governor-list';

export const RZR_VAULT = 'lndr-vault';

export const USDC_VAULT = 'usdc-vault';

export const VRZR_VAULT = 'vlndr-vault';

export const VRZR_MINT_TAG = 'vlndr-mint';

export const RZR_STAKES_TAG = 'lndr-stakes';

export const RZR_STAKE_NONCE_TAG = 'lndr-stake-nonce';

export const VRZR_STAKES_TAG = 'vlndr-stakes';

export const EPOCH_STATE_TAG = 'epoch-state';

export const RANKING_TAG = 'ranking';

export const WEIGHT_TAG = 'weight';

export let NETWORK_RPC = process.env.REACT_APP_NETWORK_TYPE;
//export let NETWORK_RPC = "localnet";

export const CHANGE_ADMIN_ADDRESS = 1;
export const CHANGE_ADMIN_LIMIT = 2;
export const CHANGE_APPROVED_ADDRESS = 3;
export const CHANGE_APPROVED_LIMIT = 4;
export const CHANGE_APPROVED_REQUEST_FEE = 5;
export const CHANGE_APPROVED_WITHDRAW_FEE = 6;
export const CHANGE_UNAPPROVED_REQUEST_FEE = 7;
export const CHANGE_UNAPPROVED_WITHDRAW_FEE = 8;
export const CHANGE_COMMIT_FEE = 9;
export const CHANGE_CLAIM_FEE = 10;
export const CHANGE_GAS_FEE = 11;
export const CHANGE_EXTRA_PERIOD = 12;
export const CHANGE_GOVERNOR_ADDRESS = 13;
export const CHANGE_FEE_VAULT_BURNED_RATE = 14;
export const CHANGE_USDC_DISTRIBUTION_RATE_FOR_STAKED_VRZR = 15;
export const CHANGE_EXPENSE_ACCOUNT = 16;
export const CHANGE_MIN_VRZR = 17;
export const CHANGE_PERMANENT_GOVERNOR = 18; 

export const MAX_OWNER_COUNT = 11;
export const MAX_APPROVED_LIST_COUNT = 10;

export const LOCAL_NETWORK = "http://localhost:8899";
export const NETWORK = process.env.REACT_APP_NETWORK_RPC || 'https://api.devnet.solana.com';
export const DEV_WSS_NETWORK = process.env.REACT_APP_WSS_NETWORK
// export const DEV_NETWORK = "https://solana-devnet.g.alchemy.com/v2/WxeE6b0_bn1XVlyBDcGQOhKQqb7Y9xFu";
export const MAIN_NETWORK = "https://api.mainnet-beta.solana.com";

export const TRADE_ON_DEX_MARKET = process.env.REACT_APP_TRADE_ON_DEX_MARKET;
// export const TRADE_ON_DEX_MARKET = "http://localhost:3000/#/market-new/";

export const TIMER_INTERVAL = 10000;

export const TIME_UNIT_HOUR = 3600;
export const TIME_UNIT_HOUR_TEXT = "Hours";
export const TIME_UNIT_DAY = 86400;
export const TIME_UNIT_DAY_TEXT = "Days";

export const EXTRA_PERIOD_BLOCK = 60;

export const BN_SIZE = 8;

export const AWAITING_FOR_SUBSCRIPTION = 0;
export const SUBSCRIPTION_PERIOD = 1;
export const INVALID_POOL = 2;
export const LENDING_PERIOD = 3;
export const EXTRA_PERIOD = 4;
export const LENDING_EXPIRED = 5;
export const FINISHED_POOL = 6;

export const INIT_STATE_TAG = "init-state";
export const GLOBAL_STATE_TAG = "global-state";
export const CREATOR_TAG = "creator";
export const POOL_TAG = "pool";
export const LENDING_VAULT_TAG = "lending-vault";
export const LENDING_VAULT_AUCTION_TAG = 'lending-vault-auction';
export const BORROWER_TAG = "borrower-tag";

export const LENDER_TAG = "borrower";
export const ISSUANCE_FEE_VAULT_TAG = "issuance-fee-vault";
export const MULTISIG_TAG = "multisgi-tag";
export const FEE_TAG = "superbond-fee-tag";
export const FEE_VAULT_TAG = 'superbond-fee-tag';
export const TREASURY_VAULT_TAG = 'superbond-treasury-tag';
export const GLOBALSTATE_UPDATION_TAG = "global-state-updation-tag";
export const WITHDRAW_TAG = "withdraw-tag";
export const POOL_TOKEN_TAG = "pool-token-tag";
export const COLLATERAL_VAULT_TAG = "collateral-vault";
export const BONUS_VAULT_TAG = "bonus-token-tag";

export const DELAY_TIME = 1000;
export const DELAY_TIME_TX = 1000;

export const decimalUSDC = 1000000; // USDC decimal value
export const decimalSB = 1000000000; // RZR decimal value 10^8

export const decimalSOL = 1000000000; // SOL decimal value
export const MIN_MARKET_FEE = 1500000000;

export const decimalNumberUSDC = 6;

export const COUPON_RATE_SCALE = 10000;

export const DAO_GLOBAL_STATE_TAG = "global-state";

export const DAO_GOVERNOR_LIST_TAG = 'governor-list';

export const PROPOSAL_INPUT = 1;
export const PROPOSAL_DISPLAY = 2;

// Proposal status
export const ACTIVE_PROPOSAL = 1;
export const SUCCEED_PROPOSAL = 2;
export const FAILED_PROPOSAL = 3;
export const CANCELLED_PROPOSAL = 4;

// Creating proposal status
export const GENERAL_FAIL = 0;
export const ABLE_TO_PROPOSE = 1;
export const NEED_PERMANENT_GOVERNOR = 2;
export const NEED_MORE_VRZR = 3;

// Voting status
export const PROPOSAL_NOT_ACTIVE = 0;
export const ABLE_TO_VOTE = 1;
export const NOT_PERMANENT_GOVERNOR = 2;
export const NOT_GOVERNOR = 3;
export const GOVERNOR_VOTED = 4;        
export const CREDORA_CLIENTID = "Uq7hGEsrXE9lrRFDuYzMA1PYx"

export const CREODORA_CLIENTSECRET = "1Ay567Hd17US9AdKQNaExEXSB"

export const MORALIS_KEY = ["1HJ6LscOQWpeHhsoa1BY84TyyxXWOxcpd1LfcGdJNr0Ik7PzfdPcfNLSYb5zrIcY", "3sQlk6C0HEVPvVxmQTDOOnp4QrTfLs4tFv7Z4Af8ALA0J0PCCcXapOMZZZnWj2XR"]

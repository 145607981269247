import { TokenInfo } from "@solana/spl-token-registry";
import { Transition, Popover } from "@headlessui/react";
import {
  useConnection,
  useWallet,
  WalletContextState,
} from "@solana/wallet-adapter-react";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
  getLender,
  getPool,
  getVerifiedData,
  updateLender,
  updatePool,
} from "../../../be-calls/be-calls";
import {
  AWAITING_FOR_SUBSCRIPTION,
  LENDING_PERIOD,
  SUBSCRIPTION_PERIOD,
  decimalUSDC,
  DELAY_TIME,
  EXTRA_PERIOD,
  FINISHED_POOL,
  decimalNumberUSDC,
  COUPON_RATE_SCALE,
} from "../../../utils/constants";
import { claimByLender } from "../../../utils/instructions";
import {
  IGlobalState,
  ILender,
  IPoolInfo,
  TokenAccount,
} from "../../../utils/interfaces";
import {
  getPoolState,
  getRoundValue,
  getUnclaimedInterest,
  shortAddress,
  getUnclaimedInterestByNone,
  delay,
  getDecimal,
  setDecimal,
  getUSDCAmountFromPoolToken,
  equal_with_epsilon_error,
  defaultDecimal,
  getSymbol,
  deserializeAccount,
  getCreditScoreByPool,
} from "../../../utils/utils";
import { getMintDecimalValue, useWalletAccounts } from "../../../utils/walletManager";
import {
  ClaimButton,
  DisableButton,
  FirstTableRowHeader,
  TableText,
  VLine,
} from "./styles";
import toast from "react-hot-toast";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BN } from "@project-serum/anchor";
import { SB_MINT_ADDRESS } from "../../../utils/ids";
import { BorrowerInfoModal } from "../../StatusPool/BorrowerInfoModal";
import { StatusPool } from "../../index";
import { H4 } from "../../StatusPool/styles";
import { BsInfoCircle } from "react-icons/bs";
import { InfoModal } from "./PoolInfo";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import Swal from "sweetalert2";
import {GlobalStyle} from "../../StatusPool/styles"
import { useUIContext } from "../../../context/AppContext";


type PoolProps = {
  poolInfo: IPoolInfo;
  userAccounts: TokenAccount[];
  tokenMap: Map<string, TokenInfo>;
  adminInfo: IGlobalState;
  wallet: WalletContextState;
  feeAccount: TokenAccount;
  index: number;
};

export const CommitedPool = ({
  poolInfo,
  userAccounts,
  tokenMap,
  wallet,
  feeAccount,
  index,
}: PoolProps) => {
  const [pInfo, setPInfo] = useState<IPoolInfo>(poolInfo);

  const { connection } = useConnection();
  const [poolState, setPoolState] = useState(AWAITING_FOR_SUBSCRIPTION);
  const [poolStateText, setPoolStateText] = useState("Subscription period");
  const nf = new Intl.NumberFormat();
  const [collateralDecimal, setCollateralDecimal] = useState(1);
  const [bonusDecimal, setBonusDecimal] = useState(1);
  const [lendingInfo, setLendingInfo] = useState<ILender | undefined>(
    undefined
  );
  const [isNFT, setIsNFT] = useState(false);

  const [auctionUsdc, setAuctionUsdc] = useState(0);
  
  const [userCommittedAmount, setUserCommittedAmount] = useState(0);
  const [userCommittedUSDCAmount, setUserCommittedUSDCAmount] = useState(0);
  const [userUnclaimedAmount, setUserUnclaimedAmount] = useState(0);
  const [remainingInVault, setRemainingInVault] = useState(0);
  const [canRepay, setCanRepay] = useState(false);
  const [isClaimable, setIsClaimable] = useState(false);
  const [verifiedData, setVerifiedData] = useState<any>(null);
  const {adminInfo} = useUIContext()

  const  openFeesModal= async ()=> {
    const claimFee = getRoundValue(Number(userCommittedUSDCAmount) / decimalUSDC * Number(adminInfo.claimFee) / 10000, defaultDecimal)
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
        ${(claimFee > 0) ? `<tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                 Claim Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${claimFee}</b> USDC</span>
              </td>
            </tr>`: ''}

            <tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                  Gas Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${Number(adminInfo.gasFeeConverted)}</b> RZR</span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    `;
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        claimClicked();
      }
    });

  }  

  const fetchVerifiedData = async (pubKey: string) => {
    let user_data = await getVerifiedData(pubKey);

    if (user_data && user_data.data.message === "SUCCESS") {
      setVerifiedData(user_data.data.user);
    } else
      setVerifiedData({
        name: undefined,
        about: undefined,
        email: undefined,
        addresses: [],
        telegramUrl: undefined,
        discordUrl: undefined,
        twitterUrl: undefined,
        website: undefined,
      });
  };
  useEffect(() => {
    fetchVerifiedData(pInfo.account.owner.toBase58());
  }, [pInfo.account.owner]);

  const claimClicked = async () => {   
    const currAccount = userAccounts.find(
      (acc) => acc.info.mint.toBase58() === pInfo.account.lendingMint.toBase58()
    );

    if (currAccount) {
      toast("Start claiming");
      const tx = await claimByLender(
        connection,
        wallet,
        pInfo.publicKey,
        pInfo.account.poolSeedSrc,
        pInfo.account.lendingMint,
        currAccount.pubkey,
        adminInfo.feeVaultTreasury,
        adminInfo.feeMint,
        adminInfo.feeVault,
        feeAccount.pubkey,
        pInfo.account.bonusTokenMint,
        pInfo.account.bonusTokenVault,
        pInfo.account.mintPoolToken,
        pInfo.account.collateralToken,
        pInfo.account.collateralVault,
        new BN(userCommittedAmount)
      );
      //console.log(" claimByLender tx = ", tx);
      if (tx) {
        await delay(DELAY_TIME);
        //Update lender to DB
        if (lendingInfo) await updateLender(lendingInfo?.publicKey.toBase58());

        let updatedPoolInfo = await updatePool(pInfo.publicKey.toBase58());

        if (updatedPoolInfo.status === "RECEIVED") {
          await delay(DELAY_TIME);
          //Update new pool data from DB to pInfo
          let poolData = await getPool(pInfo.publicKey.toBase58());

          if (poolData.status === "RECEIVED") {
            const resArr = await getCreditScoreByPool([poolData.data as IPoolInfo])
            setPInfo(resArr[0]);
            
            toast("Complete claiming");
          }
        }
      } else toast("Claiming tx is failed");
    } else {
      toast("Error to get user vault");
    }
  };

  const { connected, publicKey } = useWallet();
  const userAccs = new Map<string, TokenAccount>();
 
  const selectUserAccounts = useCallback(() => {
    //console.log("selectUserAccounts");
    return [...userAccs.values()].filter(
      (a) => a.info.owner.toBase58() === publicKey?.toBase58()
    );
  }, [publicKey, pInfo]);

  useEffect(() => {
    connection.getTokenSupply(new PublicKey(pInfo.account.collateralToken)).then((data) => {
      // console.log("getTokenSupply collateralToken = ", pInfo.account.collateralToken.toBase58(), " decimals = ", data.value.decimals, " amount = ", data.value.amount);
      // NFT
      if (data.value.decimals == 0 && data.value.amount === "1") { 
        // console.log("getTokenSupply 2");
        setIsNFT(true); 
      }
    })
  }, [connection]);

  useEffect(() => {
    //console.log("Update userAccounts");
    if(!adminInfo?.extraPeriod) return 
    const getAllAccounts = async (walletAddress: string) => {
      const accounts = await connection.getTokenAccountsByOwner(
        new PublicKey(walletAddress),
        {
          programId: TOKEN_PROGRAM_ID,
        }
      );

      accounts.value
        .map((info) => {
          const data = deserializeAccount(info.account.data);
          const details = {
            pubkey: info.pubkey,
            account: { ...info.account },
            info: data,
          } as TokenAccount;
          return details;
        })
        .forEach((acc) => {
          userAccs.set(acc.pubkey.toBase58(), acc);
        });
    };
    const getOwnerAccountInfo = async () => {
      if (connected) {
        await getAllAccounts(publicKey!.toBase58());
        userAccounts = selectUserAccounts();
 
        //console.log("UpdateStates++++"); 
        updateStates();
        await delay(DELAY_TIME);
      }
    };
    getOwnerAccountInfo();
  }, [connected, connection, publicKey, selectUserAccounts, pInfo, adminInfo]);

  const updateStates = () => {
    let userConvertedUSDCAmount: number = 0;
    let unclaimedAmount: number = 0;
    let userCommited: number = 0;
    let remainingInPool: number = 0;

    let scale: number = 3153600000 * COUPON_RATE_SCALE;
    let scaled_conversion_rate: number = pInfo.account.couponRate * pInfo.account.lendingPeriod;

    if (
      pInfo.account.totalLockedAmount.toNumber() >
      pInfo.account.totalClaimedAmount.toNumber()
    ) {
      remainingInPool =
        pInfo.account.totalLockedAmount.toNumber() -
        pInfo.account.totalClaimedAmount.toNumber();
    } else {
      remainingInPool = 0;
    }

    let found = false;
    pInfo.usersList.forEach((user) => {
      if (user.account.owner.toBase58() === wallet.publicKey?.toBase58()) {
        const poolToken = userAccounts.find(
          (token) =>
            token.info.mint.toBase58() ===
            pInfo.account.mintPoolToken.toBase58()
        );

        userCommited = poolToken?.info.amount.toNumber() || 0;
        setUserCommittedAmount(userCommited);

        //Lender doesn't receive the principle if totalLockedAmount < min
        if (pInfo.account.totalLockedAmount < pInfo.account.min) {
          userConvertedUSDCAmount = 0;
          setUserCommittedUSDCAmount(0);
        } else {
          userConvertedUSDCAmount = Math.min(
            getUSDCAmountFromPoolToken(pInfo, userCommited),
            (remainingInPool * userCommited) /
              pInfo.account.totalMintedAmount.toNumber()
          );
          setUserCommittedUSDCAmount(userConvertedUSDCAmount);
        }

        unclaimedAmount = getUnclaimedInterest(
          pInfo,
          userConvertedUSDCAmount,
          user
        );
        setUserUnclaimedAmount(unclaimedAmount);

        found = true;
      }
    });

    if (!found) {
      const poolToken = userAccounts.find(
        (token) =>
          token.info.mint.toBase58() === pInfo.account.mintPoolToken.toBase58()
      );
      userCommited = poolToken?.info.amount.toNumber() || 0;
      setUserCommittedAmount(userCommited);

      userConvertedUSDCAmount = Math.min(
        getUSDCAmountFromPoolToken(pInfo, userCommited),
        (remainingInPool * userCommited) /
          pInfo.account.totalMintedAmount.toNumber()
      );
      setUserCommittedUSDCAmount(userConvertedUSDCAmount);

      unclaimedAmount = getUnclaimedInterestByNone(
        pInfo,
        userConvertedUSDCAmount
      );
      setUserUnclaimedAmount(unclaimedAmount);
    }

    //console.log("poolToken = ", pInfo.account.mintPoolToken.toBase58(), " userCommited = ", userCommited, " userConvertedUSDCAmount = ", userConvertedUSDCAmount, " unclaimedAmount = ", unclaimedAmount, " totalClaimedAmount = ", pInfo.account.totalClaimedAmount.toNumber());

    if (pInfo.account.isWithdrawed == 1) {
      let totalClaimRepay: number =
        (pInfo.account.totalLockedAmount.toNumber() * scaled_conversion_rate) /
        scale;
      if (
        totalClaimRepay > pInfo.account.totalClaimedInterestAmount.toNumber()
      ) {
        remainingInPool +=
          totalClaimRepay - pInfo.account.totalClaimedInterestAmount.toNumber();
      }
    }

    setRemainingInVault(remainingInPool);

    if (
      pInfo.account.isWithdrawed == 1 &&
      equal_with_epsilon_error(
        pInfo.account.totalClaimedAmount.toNumber(),
        0,
        decimalNumberUSDC
      ) &&
      equal_with_epsilon_error(
        pInfo.account.totalClaimedInterestAmount.toNumber(),
        0,
        decimalNumberUSDC
      )
    ) {
      setCanRepay(true);
    }

    const { poolState, poolStateText } = getPoolState(
      pInfo,
      adminInfo.extraPeriod,
      decimalUSDC
    );

    setPoolState(poolState);
    setPoolStateText(poolStateText);
    checkClaimable(poolState, userCommited, remainingInPool);
  };

  useEffect(() => {
    let collateralDecimal = getDecimal(
      pInfo.account.collateralToken.toBase58()
    );

    if (!collateralDecimal) {
      getMintDecimalValue(pInfo.account.collateralToken).then(
        (collateralDecimal) => {
          setDecimal(
            pInfo.account.collateralToken.toBase58(),
            collateralDecimal
          );
          setCollateralDecimal(collateralDecimal);
        }
      );
    } else setCollateralDecimal(collateralDecimal);

    if (pInfo.account.bonusAmount.toNumber() > 0) {
      let bonusDecimal = getDecimal(pInfo.account.bonusTokenMint.toBase58());

      if (!bonusDecimal) {
        getMintDecimalValue(pInfo.account.bonusTokenMint).then(
          (bonusDecimal) => {
            setDecimal(pInfo.account.bonusTokenMint.toBase58(), bonusDecimal);
            setBonusDecimal(bonusDecimal);
          }
        );
      } else setBonusDecimal(bonusDecimal);
    }

    getLender(wallet.publicKey!.toBase58(), pInfo.publicKey.toBase58()).then(
      (info: any) => {
        let myInfo: ILender | undefined = undefined;
        if (info.status === "RECEIVED") myInfo = info.data;
        setLendingInfo(myInfo);
      }
    );
  }, [
    //adminInfo.extraPeriod,
    //bonusDecimal,
    //connection,
    pInfo,
    //userAccounts,
    //wallet,
    //wallet.publicKey,
  ]);

  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };

  const checkClaimable = (
    poolState: number,
    userCommited: number,
    remainingInPool: number
  ) => {
    // console.log("checkClaimable pool = ", pInfo.publicKey.toBase58(), " isNFT = ", isNFT, " aNFT = ", aNFT);
    if (
      poolState === AWAITING_FOR_SUBSCRIPTION ||
      poolState === LENDING_PERIOD ||
      poolState === SUBSCRIPTION_PERIOD ||
      poolState === EXTRA_PERIOD ||
      poolState === FINISHED_POOL
    ) {
      setIsClaimable(false);
    } else if (userCommited == 0) setIsClaimable(false);
    else if (isNFT) {
      console.log("checkClaimable isWithdrawed = ", pInfo.account.isWithdrawed, " totalClaimedAmount = ", pInfo.account.totalClaimedAmount.toNumber(), " totalClaimedInterestAmount = ", pInfo.account.totalClaimedInterestAmount.toNumber());
      if (pInfo.account.isWithdrawed == 1 &&
         getRoundValue(pInfo.account.totalClaimedAmount.toNumber(), defaultDecimal) == 0 &&
         getRoundValue(pInfo.account.totalClaimedInterestAmount.toNumber(), defaultDecimal) == 0
        )
        setIsClaimable(true);
      else {
        connection.getTokenAccountBalance((pInfo.account.poolUsdcVault)).then((data) => {
          // console.log("poolUsdcVault amount = ", data.value.amount);
          const value = (new BN(data.value.amount)).toNumber();
          console.log(data.value.amount, 'data.value.amountdata.value.amount');
          
          if (value > 0) {
            setIsClaimable(true);
            setAuctionUsdc(value);
          }  
          else setIsClaimable(false);

        });
      }
    } else if (
      !equal_with_epsilon_error(remainingInPool, 0, decimalNumberUSDC) ||
      pInfo.account.collateralAmount.toNumber() > 0 ||
      pInfo.account.bonusAmount.toNumber() > 0
    )
      setIsClaimable(true);
    else 
      setIsClaimable(false);         
  };

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
    

      <InfoModal
        openModal={openModal}
        isOpen={isOpen}
        closeModal={closeModal}
        pInfo={pInfo}
        index={index}
        adminInfo={adminInfo}
        feeAccount={feeAccount}
        wallet={wallet}
        userAccounts={userAccounts}
        tokenMap={tokenMap}
      />
      <tr>
        <td colSpan={4}>
          {" "}
          <FirstTableRowHeader type={poolStateText}>
            {userCommittedAmount > 0 &&
              `Pool-${index + 1}  \u00A0\  Status: ${poolStateText} `}
          </FirstTableRowHeader>
        </td>
      </tr>
      {userCommittedAmount > 0 && (
        <tr className="table-row dark:bg-gray-800 text-white">
          <th
            scope="row"
            className="table-section1 px-6 font-medium  whitespace-nowrap  "
          >
            <VLine color="#01fcfc" />
            <TableText className="inline">
              <BorrowerInfoModal
                pool={{
                  middleContent: {
                    statusContent: {
                      borrowers: {
                        name: shortAddress(pInfo.account.owner.toBase58()),
                        amount: shortAddress(pInfo.account.owner.toBase58()),
                      },
                    },
                  },
                }}
                verifiedData={verifiedData}
              />
              <CopyToClipboard
                onCopy={CopyAction}
                text={pInfo.account.owner.toBase58()}
              >
                <BiCopy className=" ml-1 text-[#01C0FC] text-base cursor-pointer" />
              </CopyToClipboard>
            </TableText>
          </th>

          <td className="px-6  table-section1">
            <TableText>
              <H4
                onClick={() => openModal()}
                className="tracking-wider cursor-pointer hover:text-[#01FCFC] hover:border-b hover:border-[#01C0FC] max-h-[15px]"
              >
                {shortAddress(pInfo.account.mintPoolToken.toBase58())}
              </H4>
              <BsInfoCircle onClick={() => openModal()} className="ml-1 mt-0.5 cursor-pointer text-xs text-[#01C0FC]" />
              {/*{shortAddress(pInfo.account.mintPoolToken.toBase58())}*/}
              {/* Show pool info here */}

              {/* <Popover className="relative">
                {({ open }) => (
                    <>
                    <Popover.Button className="flex">
                      <H4 className="tracking-wider cursor-pointer hover:text-[#01FCFC] hover:border-b hover:border-[#01C0FC] max-h-[15px]">
                        {shortAddress(pInfo.account.mintPoolToken.toBase58())}
                      </H4>
                      <BsInfoCircle className="ml-1 mt-0.5 cursor-pointer text-xs text-[#01C0FC]" />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
        
                      <Popover.Panel style={{top: '-200px' }} className="absolute z-10 mt-3 transform px-4 sm:px-0 transform overflow-hidden rounded-2xl bg-[#28333F] p-6 text-left align-middle shadow-xl transition-all">
                        <StatusPool
                            key={index}
                            poolInfo={pInfo}
                            feeVault={adminInfo.feeVaultTreasury}
                            feeMint={adminInfo.feeMint}
                            sbFeeVault={adminInfo.feeVault}
                            userSbVault={feeAccount?.pubkey!}
                            extraPeriod={adminInfo?.extraPeriod}
                            wallet={wallet}
                            userAccounts={userAccounts}
                            tokenMap={tokenMap}
                            isDisableBorrowerInfo={true}
                        />
                      </Popover.Panel>
                    </Transition>
                    </>
                )}
              </Popover> */}
              <CopyToClipboard
                onCopy={CopyAction}
                text={pInfo.account.mintPoolToken.toBase58()}
              >
                <BiCopy className=" ml-1 text-[#01C0FC] text-base cursor-pointer" />
              </CopyToClipboard>
            </TableText>
          </td>
          <td className="px-6  table-section1">
            <TableText>
              {nf.format(
                pInfo.account.totalLockedAmount.toNumber() / decimalUSDC
              )}{" "}
              USDC
            </TableText>
          </td>
          <td className="px-6  table-section1">
            <TableText>
              {nf.format(
                pInfo.account.collateralAmount.toNumber() / collateralDecimal
              )}{" "}
              {collateralDecimal != 1? getSymbol(pInfo.account.collateralToken.toBase58(), tokenMap) : "NFT"}
            </TableText>
          </td>
          <td className="px-6  table-section1">
            <TableText>
              {/* {nf.format(
                getRoundValue(userCommittedAmount / decimalUSDC, defaultDecimal)
              )} */}
                {nf.format(getRoundValue(pInfo.account.totalMintedAmount.toNumber() / decimalUSDC, defaultDecimal))}
            </TableText>
          </td>
          <td className="px-6  table-section">
            <TableText>
              {/* {nf.format(
                getRoundValue(userUnclaimedAmount / decimalUSDC, defaultDecimal)
              )}{" "}
              USDC */}
                {nf.format(
                getRoundValue(userCommittedAmount / decimalUSDC, defaultDecimal)
              )} Pool Token
            </TableText>
          </td>
          {/* <td className="px-6  table-section">
            <TableText>
              {nf.format(
                getRoundValue(
                  userCommittedUSDCAmount / decimalUSDC,
                  defaultDecimal
                )
              )}{" "}
              USDC/{" "}
              {
                canRepay
                ? 0
                : isNFT && auctionUsdc > 0 ? 
                  nf.format(
                    getRoundValue(
                      (userCommittedAmount * auctionUsdc) /
                      (pInfo.account.totalMintedAmount.toNumber() * decimalUSDC),
                      defaultDecimal
                    )
                  ) + " USDC"
                : !isNFT ? nf.format(
                    getRoundValue(
                      (userCommittedAmount * pInfo.account.collateralAmount.toNumber()) /
                      (pInfo.account.totalMintedAmount.toNumber() * collateralDecimal),
                      defaultDecimal
                    )
                  ) + " " + getSymbol(pInfo.account.collateralToken.toBase58(), tokenMap)
                : "0 USDC"
              }
            </TableText>
          </td> */}
          <td className="px-6  table-section">
            <TableText>
              {nf.format(
                getRoundValue(
                  (userCommittedAmount * pInfo.account.bonusAmount.toNumber()) /
                    (pInfo.account.totalMintedAmount.toNumber() * bonusDecimal),
                  defaultDecimal
                )
              )}{" "}
              {getSymbol(pInfo.account.bonusTokenMint.toBase58(), tokenMap)}
            </TableText>
          </td>
          <td className="px-6  table-section">
            {!isClaimable ? (
              <DisableButton>
                <span>Claim</span>{" "}
              </DisableButton>
            ) : (
              <ClaimButton onClick={openFeesModal}>
                <span>Claim</span>{" "}
              </ClaimButton>
            )}
          </td>
        </tr>
      )}
      <GlobalStyle />
    </>
  );
};
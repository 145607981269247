import { useEffect, useState } from "react";
import {
  Button,
  CardWrapper,
  DetailCard,
  DetailLowePart,
  DisabledButton,
  HorizentalBar,
  PoolText,
} from "./styles";
import BorrowersRight from "./BorrowersRight";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { IGlobalState, IPoolInfo, TokenAccount } from "../../utils/interfaces";
import {
  AWAITING_FOR_SUBSCRIPTION,
  FINISHED_POOL,
  INVALID_POOL,
  LENDING_EXPIRED,
  TIME_UNIT_HOUR,
  TIME_UNIT_HOUR_TEXT,
  decimalUSDC,
  DELAY_TIME,
  TIME_UNIT_DAY,
  decimalNumberUSDC,
  COUPON_RATE_SCALE,
  LENDING_PERIOD,
  EXTRA_PERIOD,
  SUBSCRIPTION_PERIOD,
  TRADE_ON_DEX_MARKET,
} from "../../utils/constants";
import { getMintDecimalValue } from "../../utils/walletManager";
import {
  defaultDecimal,
  delay,
  equal_with_epsilon_error,
  formatDateTimeInDayAndHours,
  getDateFromSec,
  getDecimal,
  getPoolState,
  getPoolStateText,
  getRoundValue,
  getSolScanUrl,
  getSymbol,
  setDecimal,
  shortAddress,
  truncateStr,
} from "../../utils/utils";
import { cancelPool, takeCollateral } from "../../utils/instructions";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { EMPTY_ADDRESS, SB_MINT_ADDRESS } from "../../utils/ids";
import { TokenInfo } from "@solana/spl-token-registry";
import toast from "react-hot-toast";
import {
  getMarket,
  getPool,
  getVerifiedData,
  setCancelledState,
  updatePool,
} from "../../be-calls/be-calls";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import dayjs from "../../utils/dayjs";
import {
  ButtonWrapper,
  FundedButton,
  RightArrow,
  SpanText,
} from "../StatusPool/styles";
import { FundedArrowImg } from "../../assets";
import { BN } from "@project-serum/anchor";
import { BorrowerInfoModal } from "../StatusPool/BorrowerInfoModal";
import { GlobalStyle } from "../StatusPool/styles";
import Swal from "sweetalert2";
import { useUIContext } from "../../context/AppContext";

type PoolProps = {
  active: Boolean;
  extraPeriod: number;
  poolInfo: IPoolInfo;
  userAccounts: TokenAccount[];
  tokenMap: Map<string, TokenInfo>;
  adminInfo: IGlobalState;
  setShowModal: (e: boolean) => void;
  setPoolInfo: (poolInfo: IPoolInfo) => void;
};

const Borrowers = ({
  active,
  extraPeriod,
  poolInfo,
  userAccounts,
  tokenMap,
  setShowModal,
  setPoolInfo,
}: PoolProps) => {
  const [pInfo, setPInfo] = useState<IPoolInfo>(poolInfo);

  const { connection } = useConnection();
  const wallet = useWallet();
  var nf = new Intl.NumberFormat();
  const [activeMenu, setActiveMenu] = useState("first");
  const [bonusDecimal, setBonusDecimal] = useState(1);
  const [collateralDecimal, setCollateralDecimal] = useState(1);

  const [feeAccount, setFeeAccount] = useState<TokenAccount | undefined>(
    undefined
  );
  const [collateralToken, setCollateralToken] = useState<TokenInfo | undefined>(
    undefined
  );

  const [poolState, setPoolState] = useState(AWAITING_FOR_SUBSCRIPTION);
  const [poolStateText, setPoolStateText] = useState("Active Subscription");
  const [disableTradeOnDex, setDisableTradeOnDex] = useState(false);
  const [verifiedData, setVerifiedData] = useState<any>(null);
  const { adminInfo } = useUIContext();

  useEffect(() => {
    const { poolState, poolStateText } = getPoolState(
      pInfo,
      extraPeriod,
      decimalUSDC
    );
    setPoolState(poolState);
    setPoolStateText(poolStateText);

    /*
    getMintDecimalValue(pInfo.account.collateralToken).then((value) =>
      setCollateralDecimal(value)
    );
    getMintDecimalValue(pInfo.account.bonusTokenMint).then((value) =>
      setBonusDecimal(value)
    );
    */

    let collateralDecimal = getDecimal(
      pInfo.account.collateralToken.toBase58()
    );

    if (!collateralDecimal) {
      getMintDecimalValue(pInfo.account.collateralToken).then(
        (collateralDecimal) => {
          setDecimal(
            pInfo.account.collateralToken.toBase58(),
            collateralDecimal
          );
          setCollateralDecimal(collateralDecimal);
          //console.log("after getMintDecimalValue collateralDecimal = ", collateralDecimal);
        }
      );
    } else setCollateralDecimal(collateralDecimal);

    let bonusDecimal = getDecimal(pInfo.account.bonusTokenMint.toBase58());

    if (!bonusDecimal && pInfo?.account?.bonusTokenMint.toString() !== EMPTY_ADDRESS) {
      getMintDecimalValue(pInfo.account.bonusTokenMint).then((bonusDecimal) => {
        setDecimal(pInfo.account.bonusTokenMint.toBase58(), bonusDecimal);
        setBonusDecimal(bonusDecimal);
      });
    } else setBonusDecimal(bonusDecimal);

    const found = userAccounts.find(
      (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
    );
    setFeeAccount(found);

    const collateralToken = tokenMap.get(
      pInfo.account.collateralToken.toBase58()
    );
    setCollateralToken(collateralToken);

    //console.log("collateralDecimal = ", collateralDecimal, " bonusDecimal = ", bonusDecimal);
  }, [extraPeriod, pInfo, tokenMap, userAccounts]);

  const fetchVerifiedData = async (pubKey: string) => {
    let user_data = await getVerifiedData(pubKey);

    if (user_data && user_data.data.message === "SUCCESS") {
      //console.log('data',user_data.data);
      setVerifiedData(user_data.data.user);
    } else
      setVerifiedData({
        name: undefined,
        about: undefined,
        email: undefined,
        addresses: [],
        telegramUrl: undefined,
        discordUrl: undefined,
        twitterUrl: undefined,
        website: undefined,
      });
  };

  useEffect(() => {
    const poolState = pInfo.account.state;
    const poolStateText = getPoolStateText(poolState, pInfo);

    setPoolState(poolState);
    setPoolStateText(poolStateText);

    //console.log("BorrowersPool poolState = ", poolState, " poolStateText = ", poolStateText, " totalLockedAmount = ", pInfo.account.totalLockedAmount.toNumber(), " min = ", pInfo.account.min.toNumber());

    let collateralDecimal = getDecimal(
      pInfo.account.collateralToken.toBase58()
    );

    if (!collateralDecimal) {
      getMintDecimalValue(pInfo.account.collateralToken).then(
        (collateralDecimal) => {
          setDecimal(
            pInfo.account.collateralToken.toBase58(),
            collateralDecimal
          );
          setCollateralDecimal(collateralDecimal);
        }
      );
    } else setCollateralDecimal(collateralDecimal);

    connection.getTokenSupply(pInfo.account.mintPoolToken).then((data) => {
      if (new BN(data.value.amount).isZero()) {
        setDisableTradeOnDex(true);
      }
    });
    fetchVerifiedData(pInfo.account.owner.toBase58());
  }, [
    connection,
    pInfo.account.collateralToken,
    pInfo.account.lendingMint,
    pInfo.account.mintPoolToken,
    pInfo.account.state,
  ]);

  const isPoolCollateralTakable = () => {
    return (
      (poolState === FINISHED_POOL ||
        poolState === LENDING_EXPIRED ||
        poolState === INVALID_POOL) &&
      pInfo.account.collateralAmount.toNumber() > 0 &&
      (pInfo.account.totalLockedAmount.toNumber() <
        pInfo.account.min.toNumber() ||
        (pInfo.account.isWithdrawed == 1 &&
          equal_with_epsilon_error(
            pInfo.account.totalClaimedAmount.toNumber(),
            0,
            decimalNumberUSDC
          )))
    );
  };

  const collateralClick = async () => {
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
           
            <tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                  Gas Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${Number(adminInfo.gasFeeConverted)}</b> RZR</span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    `;
    let ProceedForStake = false;
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        ProceedForStake = true;
      }
    });
    if (!ProceedForStake) return;
    let sbFeeAccount = feeAccount;

    if (!sbFeeAccount) {
      const found = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
      );
      if (!found) {
        toast("fee account not found");
        return;
      }

      setFeeAccount(found);
      sbFeeAccount = found;
    }

    toast("Start claiming");
    let info = await takeCollateral(
      connection,
      wallet,
      pInfo.publicKey,
      adminInfo.feeMint,
      adminInfo.feeVault,
      sbFeeAccount.pubkey,
      pInfo.account.collateralToken,
      pInfo.account.collateralVault
    );

    if (info.status === "OK") {
      await delay(DELAY_TIME);
      let updatedPoolInfo = await updatePool(pInfo.publicKey.toBase58());

      if (updatedPoolInfo.status === "RECEIVED") {
        await delay(DELAY_TIME);
        //Update new pool data from DB to pInfo
        let poolData = await getPool(pInfo.publicKey.toBase58());

        if (poolData.status === "RECEIVED") {
          setPInfo(poolData.data as IPoolInfo);
          toast("Complete claiming");
        }
      }
    }
  };

  const isPoolCancellable = () => {
    if (
      pInfo.account.min.toNumber() < pInfo.account.totalLockedAmount.toNumber()
    )
      return false;
    return poolState < INVALID_POOL;
  };

  const cancelClick = async () => {
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
           
            <tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                  Gas Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${Number(adminInfo.gasFeeConverted)}</b> RZR</span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    `;
    let ProceedForStake = false;
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        ProceedForStake = true;
      }
    });

    if (!ProceedForStake) return;
    if (!feeAccount) {
      toast("fee account is null");
      return;
    }

    if (isPoolCancellable()) {
      toast("Start cancelling pool");
      let tx = await cancelPool(
        connection,
        wallet,
        pInfo.publicKey,
        adminInfo.feeMint,
        adminInfo.feeVault,
        feeAccount.pubkey,
        pInfo.account.collateralToken,
        pInfo.account.collateralVault
      );

      if (!tx) toast("Cancelling pool failed");
      else {
        console.log("Cancel pool 1 poolState = ", poolState, " tx = ", tx);
        await delay(DELAY_TIME);

        if (
          poolState == AWAITING_FOR_SUBSCRIPTION ||
          poolState == SUBSCRIPTION_PERIOD
        ) {
          console.log("Cancel pool 2 prepare to setCancelledState");
          const obj = await setCancelledState(
            pInfo.publicKey.toBase58(),
            poolState + 1
          );

          if (obj.status === "RECEIVED") {
            console.log(
              "Cancel pool 3 cancelledState = ",
              obj.data.cancelledState
            );
          } else console.log("Cancel pool 3 cancelledState error = ", obj.data);

          await delay(DELAY_TIME);
        }

        let updatedPoolInfo = await updatePool(pInfo.publicKey.toBase58());

        if (updatedPoolInfo.status === "RECEIVED") {
          console.log("Cancel pool 4 updated PoolInfo");
          await delay(DELAY_TIME);

          //Update new pool data from DB to pInfo
          let poolData = await getPool(pInfo.publicKey.toBase58());

          if (poolData.status === "RECEIVED") {
            setPInfo(poolData.data as IPoolInfo);
            console.log(
              "Cancel pool 5 getpool collateralAmount = ",
              (poolData.data as IPoolInfo).account.collateralAmount.toNumber
            );
            toast("Complete cancellling pool");
          } else console.log("Cancel pool 5 getpool error = ", poolData.data);
        } else
          console.log(
            "Cancel pool 4 updated PoolInfo error = ",
            updatedPoolInfo.data
          );
      }
    } else {
      toast("Pool is not cancellable");
    }
  };

  const editClick = async () => {
    const message = `
    <div class="bg-gray-200 py-3 p-4 mt-3 sm:p-1 rounded-md">
      <div class="table2">
        <table class="w-full">
           
            <tr>
              <th class="text-left">
                <span class="th_span small_font_td_span">
                  Gas Fees: </span>
              </th>
              <td class="text-right">
                <span class="td_span small_font_td_span">
                <b>${Number(adminInfo.gasFeeConverted)}</b> RZR</span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    `;
    await Swal.fire({
      title: "Fees Confirmation",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#01C0FC",
    }).then((result) => {
      if (result.isConfirmed) {
        if (poolState === AWAITING_FOR_SUBSCRIPTION) {
          setShowModal(true);
          setPoolInfo(pInfo);
        }
      }
    });
  };

  const CopyAction = () => {
    toast.success("Copied Successfully!");
  };

  const getCancelButtonOption = (poolStateText: string, poolInfo: any) => {
    if (
      poolStateText === "Active Subscription" &&
      poolInfo.account.totalLockedAmount.toNumber() / decimalUSDC <=
        poolInfo.account.min.toNumber() / decimalUSDC
    ) {
      return (
        <text className="btn red" onClick={cancelClick}>
          CANCEL
        </text>
      );
    } else if (poolStateText === "Pre-Subscription")
      return (
        <text className="btn red" onClick={cancelClick}>
          CANCEL
        </text>
      );
  };

  const calcTotalFundsRepaid = () => {
    //console.log("pool token address = ", pInfo.account.mintPoolToken.toBase58(), " totalLockedAmount = ", pInfo.account.totalLockedAmount.toNumber()," couponRate = ", pInfo.account.couponRate, " lendingPeriod = ", pInfo.account.lendingPeriod, " totalClaimedAmount = ", pInfo.account.totalClaimedAmount.toNumber(), " totalClaimedInterestAmount = ", pInfo.account.totalClaimedInterestAmount.toNumber(), " interest = ", pInfo.account.totalLockedAmount.toNumber() * pInfo.account.couponRate * pInfo.account.lendingPeriod / (3153600000 * COUPON_RATE_SCALE));
    if (pInfo.account.isWithdrawed == 1) {
      let totalAmount = getRoundValue(
        (pInfo.account.totalLockedAmount.toNumber() +
          (pInfo.account.totalLockedAmount.toNumber() *
            pInfo.account.couponRate *
            pInfo.account.lendingPeriod) /
            (3153600000 * COUPON_RATE_SCALE)) /
          decimalUSDC,
        defaultDecimal
      );
      return (
        totalAmount -
        getRoundValue(
          (pInfo.account.totalClaimedAmount.toNumber() +
            pInfo.account.totalClaimedInterestAmount.toNumber()) /
            decimalUSDC,
          defaultDecimal
        )
      );
    } else return 0;
  };

  const tradeOnDex = async () => {
    let info = await getMarket(pInfo.publicKey.toBase58());
    if (info.status === "RECEIVED") {
      let marketLink = TRADE_ON_DEX_MARKET + info.data;
      window.open(marketLink);
    } else {
      toast("Cannot find market address for token pair");
    }
  };

  //console.log("lendingStartedTime", pInfo.account.lendingStartedTime)

  //console.log("-lendingPeriod ",pInfo.account.lendingPeriod)
  return (
    <div className="d-flex ">
      <CardWrapper className="w-full">
        <DetailCard active={active}>
          <div className="first-half">
            {active && (
              <div className="upper">
                <div>
                  <p className="label1">Deposited Collateral</p>
                  <p className="label2">
                    {nf.format(
                      pInfo.account.collateralAmount.toNumber() /
                        collateralDecimal
                    ) + " "}
                      <a
                      className="label3"
                        target="_blank"
                        href={getSolScanUrl(
                          pInfo.account.collateralToken.toBase58()
                        )}
                      >
                        {" "}
                        {collateralDecimal == 1
                          ? " NFT"
                          : getSymbol(
                              pInfo.account.collateralToken.toBase58(),
                              tokenMap
                            )}
                      </a>
                  </p>
                </div>
                {isPoolCollateralTakable() ? (
                  <Button onClick={collateralClick}>
                    <span>Claim</span>
                  </Button>
                ) : (
                  <DisabledButton>
                    <span>Claim</span>
                  </DisabledButton>
                )}
              </div>
            )}
            <DetailLowePart active={active}>
              <HorizentalBar sidebarColor={poolStateText} />
              <div className="div1">
                <div>
                  <p className="label1">Status</p>
                  <PoolText titleColor={poolStateText}>
                    {poolStateText}
                  </PoolText>
                </div>
                {/*<div>*/}
                {/*  <p className="label1">Borrower</p>*/}
                {/*  <BorrowerInfoModal pool={{*/}
                {/*    middleContent:*/}
                {/*        {*/}
                {/*          statusContent:*/}
                {/*              {*/}
                {/*                borrowers:*/}
                {/*                    {*/}
                {/*                      name: shortAddress(pInfo.account.owner.toBase58())*/}
                {/*                    }*/}
                {/*              }*/}
                {/*        }*/}
                {/*  }}*/}
                {/*                     verifiedData={verifiedData} />*/}
                {/*</div>*/}
                {active && (
                  <div className="div3 gap-5">
                    {poolStateText === "Pre-Subscription" && (
                      <text className="btn white" onClick={editClick}>
                        EDIT
                      </text>
                    )}
                    {getCancelButtonOption(poolStateText, pInfo)}
                  </div>
                )}
              </div>
              <div className="div2">
                <div>
                  <text className="label1">Total Commited</text>
                  <text className="sub-label">
                    {nf.format(
                      getRoundValue(
                        pInfo.account.totalLockedAmount.toNumber() /
                          decimalUSDC,
                        defaultDecimal
                      )
                    )}{" "}
                    USDC
                  </text>
                </div>
                <div>
                  <text className="label1">Borrow Yield</text>
                  <text className="sub-label">
                    {pInfo.account.couponRate / COUPON_RATE_SCALE}%
                  </text>
                </div>
                <div>
                  <text className="label1">Borrow Period</text>
                  {/*<p className="sub-label">{nf.format(getRoundValue(pInfo.account.lendingPeriod / TIME_UNIT_DAY, defaultDecimal))} DAYS</p> */}
                  <text className="sub-label">
                    {formatDateTimeInDayAndHours(pInfo.account.lendingPeriod)}
                  </text>
                </div>
              </div>
            </DetailLowePart>

            {!disableTradeOnDex && poolStateText !== "Unfunded" && poolStateText !== "Finished Unfunded" &&
            (
              poolState === LENDING_PERIOD ||
              poolState === EXTRA_PERIOD ||
              poolState === LENDING_EXPIRED ||
              poolState === FINISHED_POOL) ? (
              <ButtonWrapper
                paddingBottom={"pool.topContent.title"}
                paddingTop={"pool.middleContent.statusContent.title"}
              >
                <FundedButton onClick={tradeOnDex}>
                  <SpanText className="buttonLeft">Trade on dex</SpanText>
                  <RightArrow src={FundedArrowImg} className="buttonRight" />
                </FundedButton>
              </ButtonWrapper>
            ) : null}
          </div>
          <div className="second-half">
            <div className="second-part1">
              <div className="arrow-control">
                <h3
                  className="div2-header"
                  style={{
                    color: activeMenu !== "first" ? "#4D647B" : "#ffffff",
                  }}
                >
                  pool details
                </h3>
                {activeMenu === "first" ? (
                  <MdKeyboardArrowUp
                    onClick={() => setActiveMenu("second")}
                    className="up-arrow"
                  />
                ) : (
                  <MdKeyboardArrowDown
                    onClick={() => setActiveMenu("first")}
                    className="down-arrow"
                  />
                )}
              </div>

              <div
                style={{ display: activeMenu === "first" ? "block" : "none" }}
              >
                <div className="part2-table">
                  <text className="col1-text">Pool Token Address</text>
                  <text className="col2-text">
                    {truncateStr(pInfo.account.mintPoolToken.toBase58(), 5)}
                    <CopyToClipboard
                      onCopy={CopyAction}
                      text={pInfo.account.mintPoolToken.toBase58()}
                    >
                      <BiCopy className=" ml-1 text-[#01C0FC] text-base cursor-pointer" />
                    </CopyToClipboard>
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Subscription Start Time</text>
                  <text className="col2-text">
                    {pInfo.account.subscriptionStartTime
                      ? `${dayjs
                          .unix(pInfo.account.subscriptionStartTime)
                          .utc()
                          .format("MM-DD-YYYY HH:mm:ss")} UTC`
                      : "TBD"}
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Max Borrow</text>
                  <text className="col2-text">
                    {nf.format(
                      getRoundValue(
                        pInfo.account.goal.toNumber() / decimalUSDC,
                        defaultDecimal
                      )
                    )}{" "}
                    USDC
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Min Borrow</text>
                  <text className="col2-text">
                    {nf.format(
                      getRoundValue(
                        pInfo.account.min.toNumber() / decimalUSDC,
                        defaultDecimal
                      )
                    )}{" "}
                    USDC
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Subscription Period</text>
                  <text className="col2-text">
                    {formatDateTimeInDayAndHours(
                      pInfo.account.subscriptionPeriod
                    )}
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Borrow Start Time</text>
                  <text className="col2-text">
                    {pInfo.account.lendingStartedTime
                      ? `${dayjs
                          .unix(pInfo.account.lendingStartedTime)
                          .utc()
                          .format("MM-DD-YYYY HH:mm:ss")} UTC`
                      : "TBD"}
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Borrow Duration</text>
                  <text className="col2-text">
                    {formatDateTimeInDayAndHours(pInfo.account.lendingPeriod)}
                    {/*{pInfo.account.lendingPeriod?nf.format(getRoundValue(pInfo.account.lendingPeriod / TIME_UNIT_HOUR, defaultDecimal)):'TBD'}{" "}*/}
                    {/*{pInfo.account.lendingPeriod?TIME_UNIT_HOUR_TEXT:''} */}
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Pool Finish Time</text>
                  <text className="col2-text">
                    {pInfo.account.lendingStartedTime > 0
                      ? `${dayjs
                          .unix(
                            pInfo.account.lendingStartedTime +
                              pInfo.account.lendingPeriod
                          )
                          .utc()
                          .format("MM-DD-YYYY HH:mm:ss")} UTC`
                      : "TBD"}
                  </text>
                </div>
              </div>
            </div>

            <div className="second-part2">
              <div className="arrow-control">
                <h3
                  className="div2-header"
                  style={{
                    color: activeMenu === "first" ? "#4D647B" : "#ffffff",
                  }}
                >
                  Repayment Details
                </h3>
                {activeMenu === "second" ? (
                  <MdKeyboardArrowUp
                    onClick={() => setActiveMenu("first")}
                    className="up-arrow"
                  />
                ) : (
                  <MdKeyboardArrowDown
                    onClick={() => setActiveMenu("second")}
                    className="down-arrow"
                  />
                )}
              </div>

              <div
                style={{ display: activeMenu === "second" ? "block" : "none" }}
              >
                <div className="part2-table">
                  <text className="col1-text">Total Pool Token Amount</text>
                  <text className="col2-text">
                    {nf.format(
                      getRoundValue(
                        pInfo.account.totalMintedAmount.toNumber() /
                          decimalUSDC,
                        defaultDecimal
                      )
                    )}
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Remaining Liability</text>
                  <text className="col2-text">
                    {new Intl.NumberFormat("en", {
                      maximumFractionDigits: 6,
                    }).format(
                      getRoundValue(
                        (pInfo.account.totalClaimedAmount.toNumber() +
                          pInfo.account.totalClaimedInterestAmount.toNumber()) /
                          decimalUSDC,
                        decimalNumberUSDC
                      )
                    )}{" "}
                    USDC
                  </text>
                </div>
                {
                  <div className="part2-table">
                    <text className="col1-text">Total Funds Repaid</text>
                    <text className="col2-text">
                      {calcTotalFundsRepaid()} USDC
                    </text>
                  </div>
                }
                <div className="part2-table">
                  <text className="col1-text">Current Borrowed Amount</text>
                  <text className="col2-text">
                    {nf.format(
                      pInfo.account.totalClaimedAmount.toNumber() / decimalUSDC
                    )}{" "}
                    {getSymbol(pInfo.account.lendingMint.toBase58(), tokenMap)}
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Bonus Token Name</text>
                  <text className="col2-text">
                    {tokenMap.get(pInfo.account.bonusTokenMint.toBase58())?.name
                      ? tokenMap.get(pInfo.account.bonusTokenMint.toBase58())
                          ?.name
                      : "---"}
                  </text>
                </div>
                <div className="part2-table">
                  <text className="col1-text">Bonus Amount</text>
                  <text className="col2-text">
                    {nf.format(
                      pInfo.account.bonusAmount.toNumber() / bonusDecimal
                    )}{" "}
                    {getSymbol(
                      pInfo.account.bonusTokenMint.toBase58(),
                      tokenMap
                    )}
                  </text>
                </div>
              </div>
            </div>
          </div>
        </DetailCard>
        <BorrowersRight
          poolInfo={pInfo}
          adminInfo={adminInfo}
          userAccounts={userAccounts}
          poolState={poolState}
          tokenMap={tokenMap}
          poolStateText={poolStateText}
          setPoolInfo={setPInfo}
        />
      </CardWrapper>
      <GlobalStyle />
    </div>
  );
};

export default Borrowers;

import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect, useState } from "react";
import {
  ExpiredArrow,
  ActiveArrow,
  Logo,
  DownArrowIcon,
  TextLogo,
  FundedArrow,
  PreSubArrow,
  MobileLogo,
  MobileTextLogo,
} from "../../assets";
import { useUIContext } from "../../context/AppContext";
import { decimalSB, decimalUSDC } from "../../utils/constants";
import { SB_MINT_ADDRESS, USDC_MINT_ADDRESS } from "../../utils/ids";
import { useWalletAccounts } from "../../utils/walletManager";
import { ListRPC } from "./RPCDropdown";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import {
  DownArrow,
  Image,
  Nav,
  NavItem,
  NavLinks,
  NavLogo,
  NavMain,
  NavMenu,
  TextImage,
  DropdownList,
  DorpdownItem,
  DropdownLink,
  ChevronIcon,
  NavMenuWrapper,
  Wrapper,
  BalanceWrapper,
  SbBalance,
  SbSpan,
  UsdcBalanceWrapper,
  UsdcBalance,
  UsdcSpan,
  Heading,
  H2,
  VerticleLine,
  BorrowersDropdownList,
  DropdownLinkItem,
  BorrowersDorpdownItem,
  NavList,
  NavListItem,
  NavListLink,
  MobileNavLogo,
} from "./styles";

const Navbar = () => {
  const wallet = useWallet();
  const location = useLocation();
  const path=location.pathname;
  const { userAccounts } = useWalletAccounts();
  const [SBBalance, setSBBalance] = useState(0);
  const [USDCBalance, setUSDCBalance] = useState(0);
  const nf = new Intl.NumberFormat();

  const { endpoint, setEndpoint } = useUIContext();
  useEffect(() => {
    if (wallet.publicKey) {
      const sbToken = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === SB_MINT_ADDRESS
      );
      if (sbToken) {
        setSBBalance(Number(sbToken.info.amount) / decimalSB);
      }

      const usdcToken = userAccounts.find(
        (acc) => acc.info.mint.toBase58() === USDC_MINT_ADDRESS
      );
      if (usdcToken) {
        setUSDCBalance(Number(usdcToken.info.amount) / decimalUSDC);
      }
    }
  }, [wallet.connected, userAccounts, wallet.publicKey]);

  const explore_pool = [
    "/active-subscription-pools",
    "/funded-pools",
    "/pre-subscription-pools",
    "/expired-pools",
  ];

  const lend = ["/account"];

  const borrowers = [
    "/borrowers",
    "/create-pool",
    "/xmarginverification",
    "/selfverification",
  ];
  const secondary_markets = ["/auction"];
  const governance = ["/dao", "/governance", "/top-stakers"];
  
 
  return (
    <Nav>
      <NavMain>
        {/*start show on mobile */}
        <MobileNavLogo to="/">
          <Image
            src={
              "/images/rzr-icon.svg"
            }
            alt="Raiser"
          />
        </MobileNavLogo>

        {/* <NavList>
          <NavListItem>
            <NavListLink to="/docs">Docs</NavListLink>
          </NavListItem>
          <NavListItem>
            <NavListLink to="/community">Community</NavListLink>
          </NavListItem>
        </NavList> */}

        {/* end show on mobile */}
        <NavMenuWrapper>
          <NavLogo to="/">
            <Image
              src={
                "/images/rzr-icon.svg"
              }
              alt="Raiser"
            />
          </NavLogo>

          <NavMenu>
            <NavItem>
              <NavLinks  style={{background:explore_pool.includes(path)?'rgb(31, 40, 51)':''}} >
                Explore pools <DownArrow src={DownArrowIcon} alt="Down Arrow" />
              </NavLinks>
              <DropdownList>
                <DorpdownItem>
                  <DropdownLink
                    to="/active-subscription-pools"
                    style={{ textDecoration: "none" }}
                  >
                    Active Subscription
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLink>
                </DorpdownItem>
                <DorpdownItem>
                  <DropdownLink
                    to="/funded-pools"
                    style={{ textDecoration: "none" }}
                  >
                    Funded
                    <ChevronIcon src={FundedArrow} />
                  </DropdownLink>
                </DorpdownItem>
                <DorpdownItem>
                  <DropdownLink
                    to="/pre-subscription-pools"
                    style={{ textDecoration: "none" }}
                  >
                    Pre-Subscription
                    <ChevronIcon src={PreSubArrow} />
                  </DropdownLink>
                </DorpdownItem>
                <DorpdownItem>
                  <DropdownLink
                    to="/expired-pools"
                    style={{ textDecoration: "none" }}
                  >
                    Finished
                    <ChevronIcon src={ExpiredArrow} />
                  </DropdownLink>
                </DorpdownItem>
              </DropdownList>
            </NavItem>

            <NavItem >
              <NavLinks style={{background:lend.includes(path)?' rgb(31, 40, 51)':''}}>
                Lenders
                <DownArrow src={DownArrowIcon} alt="Down Arrow" />
              </NavLinks>
              <BorrowersDropdownList>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/account"
                    style={{ textDecoration: "none" }}
                  >
                    My Account
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
              </BorrowersDropdownList>
            </NavItem>
            <NavItem>
              <NavLinks style={{background:borrowers.includes(path)?' rgb(31, 40, 51)':''}}>
                Borrowers
                <DownArrow src={DownArrowIcon} alt="Down Arrow" />
              </NavLinks>
              <BorrowersDropdownList>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/borrowers"
                    style={{ textDecoration: "none" }}
                  >
                    My Pool
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/create-pool"
                    style={{ textDecoration: "none" }}
                  >
                    Create New Pool
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
                {/* <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/xmarginverification"
                    style={{ textDecoration: "none" }}
                  >
                    Add Credit Score
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem> */}

                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/selfverification"
                    style={{ textDecoration: "none" }}
                  >
                    Self Verification
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
              </BorrowersDropdownList>
            </NavItem>

            <NavItem>
              <NavLinks style={{background:secondary_markets.includes(path)?' rgb(31, 40, 51)':''}}>
                secondary Markets
                <DownArrow src={DownArrowIcon} alt="Down Arrow" />
              </NavLinks>
              <BorrowersDropdownList>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/auction"
                    style={{ textDecoration: "none" }}
                  >
                    NFT Auction
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>

                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to=""
                    style={{ textDecoration: "none" }}
                    onClick={()=>window.open(process.env.REACT_APP_DEX_MARKET_URL || "https://dex.raiserdapp.com/")} 
                  >
                    ZCB Market
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
              </BorrowersDropdownList>
            </NavItem>

            <NavItem>
              <NavLinks style={{background:governance.includes(path)?' rgb(31, 40, 51)':''}}>
                Governance
                <DownArrow src={DownArrowIcon} alt="Down Arrow" />
              </NavLinks>
              <BorrowersDropdownList>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/dao"
                    style={{ textDecoration: "none" }}
                  >
                    Staking & Rewards
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>

                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/governance"
                    style={{ textDecoration: "none" }}
                  >
                    Create Proposal
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>

                <BorrowersDorpdownItem>
                  <DropdownLinkItem
                    to="/top-stakers"
                    style={{ textDecoration: "none" }}
                  >
                    Top Stakers
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
              </BorrowersDropdownList>
            </NavItem>

            {/* <NavItem>
              <NavLinks>
                DAO
                <DownArrow src={DownArrowIcon} alt="Down Arrow" />
              </NavLinks>
              <BorrowersDropdownList>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem to="/dao">
                  DAO
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem to="/governance">
                    Governance
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem to="/auction">
                    AUCTION
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
                <BorrowersDorpdownItem>
                  <DropdownLinkItem to="/top-stakers">
                    Top stakers
                    <ChevronIcon src={ActiveArrow} />
                  </DropdownLinkItem>
                </BorrowersDorpdownItem>
              </BorrowersDropdownList>
            </NavItem> */}
          </NavMenu>

          {/* <GetDoxxedBtn>GET DOXXED</GetDoxxedBtn> */}
        </NavMenuWrapper>

        <Wrapper>
          <div className="balance-warpper">
            <div className="sb-balance">
              <span className="heading">
                {" "}
                {nf.format(parseFloat(SBBalance.toFixed(2)))}
              </span>
              <span className="sb-span" style={{ color: "#687d95" }}>
                {" "}
                RZR
              </span>

              <div className="line"></div>
            </div>

            <div className="two">
              <text className="h2">balance</text>
              <div className="usdv-balance">
                <span className="heading">
                  {" "}
                  {nf.format(parseFloat(USDCBalance.toFixed(2)))}
                </span>
                <span className="sb-span" style={{ color: "#687d95" }}>
                  {" "}
                  USDC
                </span>
              </div>
            </div>
          </div>

          {/* <BalanceWrapper>
            <SbBalance>
              <Heading> {nf.format(parseFloat(SBBalance.toFixed(2)) )}</Heading>
              <SbSpan style={{ color: "#687d95" }}>RZR</SbSpan>

              <VerticleLine />
            </SbBalance>
            <div>
              <H2>balance</H2>
              <UsdcBalance>
                <Heading> {nf.format(parseFloat(USDCBalance.toFixed(2)))}</Heading>
                <UsdcSpan>USDC</UsdcSpan>
              </UsdcBalance>
            </div>
          </BalanceWrapper> */}
          {/* this button style globally override */}
          <WalletMultiButton />
          <ListRPC selected={endpoint} onChangeValue={setEndpoint} />
        </Wrapper>
      </NavMain>
    </Nav>
  );
};
export default Navbar;

import {
  ILndrStake,
  useDAOContext,
  IVlndrStake,
} from "../../../pages/DAO/data";
import { ButtonWithoutEffect, TableWarpper } from "./style";
import { useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { decimalSB, decimalUSDC } from "../../../utils/constants";
import { defaultDecimal, getRoundValue } from "../../../utils/utils";

const DAOTable = () => {
  const {
    allStake,
    vlndrStakeState,
    unstake,
    claimRewardAndWithdrawVlndr,
    claimReward,
    refreshData,
    setRefreshData,
    currentEpoch,
    continueStakeVlndr,
    period,
    rank
  } = useDAOContext();
  const wallet = useWallet();
  const { connection } = useConnection();
  const [showCount, setShowCount] = useState(10);

  const checkClaim = (noti?: boolean) => {
    if (period != "GRACE") {
      if(noti) {
        toast.error("Invalid period to claim!");
      }
      return;
    }
    return true;
  };

  const checkGov = () => {
    const check = rank?.rank.findIndex(
      (item: any) => wallet?.publicKey?.toBase58() === item.toBase58()
    );
    return check > -1 && rank?.becomeGovernor[check];
  };

  const unstakeLndr = async (nonce: number, timestamp: number) => {
    if (wallet?.connected) {
      if (timestamp > Date.now() / 1000) {
        toast.error("Invalid time to claim!");
        return;
      }
      await unstake(connection, wallet, nonce);
      setRefreshData(!refreshData);
      toast.success("Claim RZR successfully!");
    }
  };

  const claimRewardVlndr = async (epoch: number) => {
    if (wallet?.connected) {
      await claimReward(connection, wallet, epoch);
      setRefreshData(!refreshData);
      toast.success("Claim reward successfully!");
    }
  };

  const claimRwAndUnstakeVlndr = async (epoch: number) => {
    if (wallet?.connected) {
      await claimRewardAndWithdrawVlndr(connection, wallet, epoch);
      setRefreshData(!refreshData);
      toast.success("Claim reward and withdraw veRZR successfully!");
    }
  };

  const continueStake = async (epoch: number) => {
    if (wallet?.connected) {
      await continueStakeVlndr(connection, wallet, epoch);
      setRefreshData(!refreshData);
      toast.success("Continue stake veRZR successfully!");
    }
  };

  const showMore = () => {
    if (vlndrStakeState.length >= showCount) setShowCount(showCount + 10);
  };

  return (
    <TableWarpper>
      <div className="table-warpper">
        <div className="table-one">
          <div className="table-card">
            <div className="header-div">
              <text className="table-header-main">Unstaked rzr</text>
            </div>

            <div className="line-break" />
            <div className="table-overflow-wrapper overflow-y-auto">
              <table className="w-full ">
                <thead className="bg-gray-[#1A232B]">
                  <th scope="col" className="py-3 px-2">
                    <text className="table-header">Serial Number</text>
                  </th>
                  <th scope="col" className="py-3 px-3">
                    <text className="table-header">Amount</text>
                  </th>
                  <th scope="col" className="py-3 px-3">
                    <text className="table-header">Release Time</text>
                  </th>
                  <th scope="col" className="py-3 px-3">
                    <text className="table-header">Action</text>
                  </th>
                </thead>
                
                <tbody className="overflow-y-scroll" >
                  {allStake?.length > 0
                    ? allStake?.map((stake: ILndrStake) => (
                        <tr className="">
                          <th
                            scope="row"
                            className="py-4 px-2 whitespace-nowrap "
                          >
                            <text className="table-text">{stake.nonce} </text>
                          </th>
                          <td className="py-4 px-2">
                            <text className="table-text">
                              {Number(stake.amount) / decimalSB}
                            </text>
                          </td>
                          <td className="py-4 px-2">
                            <text className="table-text">
                              {dayjs
                                .unix(stake.releaseTime.toNumber())
                                .utc()
                                .format("MM-DD-YYYY HH:mm:ss UTC")}
                            </text>
                          </td>
                          <td className="py-4 px-2">
                            {stake.claimed ? (
                              <text className="table-text">CLAIMED</text>
                            ) : (
                              <button
                                onClick={() =>
                                  unstakeLndr(
                                    stake.nonce,
                                    stake.releaseTime.toNumber()
                                  )
                                }
                                className={`table-btn ${
                                  stake.releaseTime.toNumber() >
                                  Date.now() / 1000
                                    ? "disable-btn"
                                    : ""
                                }`}
                                disabled={
                                  stake.releaseTime.toNumber() >
                                  Date.now() / 1000
                                }
                              >
                                <span> Claim </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
             
              </table>
            </div>
          </div>
        </div>
        <div className="table-two">
          <div className="table-card">
            <div className="header-div">
              <text className="table-header-main">Unstaked verzr & reward</text>
            </div>

            <div className="line-break" />
            <div className="table-overflow-wrapper overflow-y-auto">
              <table className="w-full ">
                <thead className="bg-gray-[#1A232B]">
                  <th scope="col" className="py-3 px-2">
                    <text className="table-header">Epoch</text>
                  </th>
                  <th scope="col" className="py-3 px-2">
                    <text className="table-header">veRZR STAKE</text>
                  </th>
                  <th scope="col" className="py-3 px-2">
                    <text className="table-header">ESTIMATED WEIGHT</text>
                  </th>
                  <th scope="col" className="py-3 px-2">
                    <text className="table-header">TOTAL WEIGHT</text>
                  </th>
                  <th scope="col" className="py-3 px-2">
                    <text className="table-header">REWARD</text>
                  </th>
                  <th scope="col" className="py-3 px-2">
                    <text className="table-header">Action</text>
                  </th>
                </thead>
                <tbody className="overflow-y-scroll">
                  {vlndrStakeState?.length > 0
                    ? vlndrStakeState?.sort((a: any, b: any) => b?.epoch - a?.epoch)?.slice(0, showCount)?.map((stake: IVlndrStake) => {
                      if(stake.epoch <= currentEpoch && !(Number(stake.totalVlndrStaked) ==0 && !stake.rewardClaimed))
                      return <tr className="">
                          <th
                            scope="row"
                            className="py-4 px-2 whitespace-nowrap "
                          >
                            <text className="table-text">{stake.epoch}</text>
                          </th>
                          <td className="py-4 px-2">
                            <text className="table-text">
                              {getRoundValue(
                                Number(stake.totalVlndrStaked) / decimalSB,
                                defaultDecimal
                              )}
                            </text>
                          </td>
                          <td className="py-4 px-2">
                            <text className="table-text">
                              {getRoundValue(
                                Number(stake.estimatedWeight) / decimalSB,
                                defaultDecimal
                              )}
                            </text>
                          </td>
                          <td className="py-4 px-2">
                            <text className="table-text">
                              {getRoundValue(
                                Number(stake.totalWeight) / decimalSB,
                                defaultDecimal
                              )}
                            </text>
                          </td>
                          <td className="py-4 px-2">
                            <text className="table-text">
                              {getRoundValue(
                                stake.estimatedReward / decimalUSDC,
                                defaultDecimal
                              )}
                            </text>
                          </td>
                          <td className="py-4 px-2">
                            {!stake.rewardClaimed ? (
                              <>
                                <button
                                  onClick={() => claimRewardVlndr(stake.epoch)}
                                  className={`table-btn mr-1 ${
                                    ((currentEpoch == stake.epoch && !checkClaim()) || stake.estimatedReward == 0) ? "disable-btn" : ""
                                  }`}
                                  disabled={((currentEpoch == stake.epoch && !checkClaim()) || stake.estimatedReward == 0)}
                                >
                                  <span>CLAIM REWARD</span>
                                </button>
                                {currentEpoch == stake.epoch && (
                                  <button
                                    className={`table-btn mr-1 ${
                                      (!checkClaim() || checkGov()) ? "disable-btn" : ""
                                    }`}
                                    disabled={!checkClaim() || checkGov()}
                                    onClick={() =>
                                      claimRwAndUnstakeVlndr(stake.epoch)
                                    }
                                  >
                                    <span>Unstake</span>
                                  </button>
                                )}
                              </>
                            ) : (
                              <text className="table-text">CLAIMED</text>
                            )}
                          </td>
                        </tr>
                      })
                    : ""}
                  
                </tbody>
              </table>
              {vlndrStakeState?.length > showCount && <div><ButtonWithoutEffect style={{margin: "5px auto"}} onClick={showMore}>
                      <span>Load More</span>
                    </ButtonWithoutEffect></div>}
            </div>
          </div>
        </div>
      </div>
    </TableWarpper>
  );
};
export default DAOTable;
